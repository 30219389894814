<template>
    <div class="searchLabels">
        <div class="rightBox">
            <span v-for="(item, index) in options" :key="item.dictItemCode" class="label ellipsis" :class="[check == item.dictItemCode+'' ? 'label2':'label1']" :style="index!=0&&(index+1)%3 == 0?'margin-right:0':''" @click="changeItem(item)">{{item.itemName}}</span>
        </div>
    </div>
</template>

<script>
// import Controls from '@/page/Client/CustPublic/Controls/index.js'
// import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
export default {
    name: 'searchLabels',
    props: {
        options: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            check: '1'
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        submit() {
            let obj = {}
            if (this.check != 1) {
                obj[this.list.fieldName] = this.check
            }
            return obj
        },
        clear() {
            this.check = '1'
            this.$emit('clearFilfter')
        },
        changeItem(value) {
            if (this.check !== value.dictItemCode) {
                this.check = value.dictItemCode
                this.$emit('anomalyCheck', value)
            }
        }
    },
    computed: {
    },
    watch: {
    },
    components: {}
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.searchLabels {
    .rightBox {
        min-height: 36px;
        .label,
        .noLabel {
            display: inline-block;
            .width(65px);
            .height(26px);
            text-align: center;
            .line-height(26px);
            .border-radius(4px);
            .font-size(12px);
            .margin-bottom(17px);
            .margin-right(19px);
        }
        .label1 {
            color: #606266;
            background: #efefef;
        }
        .label2 {
            color: white;
            background: #d0021b;
        }
        .control {
            margin-bottom: 0;
            line-height: 33px;
            display: inline-block;
        }
    }
}
</style>
