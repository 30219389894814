<template>
    <div class="mailSearch">
        <van-tabs v-model="active" style="height: 100%;z-index: 999;" @click="changeActive">
            <van-tab title="邮箱任务" style="height: 100%;z-index: 999">
                <tab-task ref="tabTask"></tab-task>
            </van-tab>
            <van-tab title="超时邮件">
                <tab-delay ref="tabDelay" :active="active"></tab-delay>
            </van-tab>
            <van-tab title="异常邮件">
                <tab-abnormal ref="tabAbnormal" :active="active"></tab-abnormal>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import tabTask from '../Vue/Tab/task'
import tabDelay from '../Vue/Tab/delay'
import tabAbnormal from '../Vue/Tab/abnormal'

export default {
    name: 'Search',
    title: '查询',
    data() {
        return {
            active: 0,
            routerParams: {}
        }
    },
    mounted() {
        this.setMenu()
    },
    created() {
        this.getTabTaskList()
    },
    methods: {
        changeActive(name) {
            if (name == 0) {
                this.getTabTaskList()
            } else if (name == 1) {
                this.$nextTick(() => {
                    this.$refs.tabDelay.getDelayListData()
                })
            } else {
                this.$nextTick(() => {
                    this.$refs.tabAbnormal.getDelayListData()
                })
            }
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        getTabTaskList() {
            this.routerParams = this.$route.query
            if (JSON.stringify(this.routerParams) != '{}') {
                this.$nextTick(() => {
                    this.$refs.tabTask.gettaskListData(this.routerParams.days, this.routerParams.type)
                })
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.path == '/mailmonitor/search') {
                if (this.active == 1) {
                    this.$nextTick(() => {
                        this.$refs.tabDelay.getDelayListData()
                    })
                } else if (this.active == 0) {
                    this.getTabTaskList()
                } else if (this.active == 2) {
                    this.$nextTick(() => {
                        this.$refs.tabAbnormal.getDelayListData()
                    })
                }
            }
        }
    },
    components: {
        'tab-task': tabTask,
        'tab-delay': tabDelay,
        'tab-abnormal': tabAbnormal
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
