<template>
    <div class="searchLabels">
        <div class="rightBox">
            <span v-for="(item, index) in options" :key="item.labelId"  class="label ellipsis" :class="[check == item.labelId+'' ? 'label2':'label1']" :style="{'margin-right':index!=0&&(index+1)%3 == 0?'0':''}" @click="changeItem(item.labelId)">{{item.labelName}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'searchLabels',
    props: {
        list: {
            type: Object,
            default: function() {
                return {}
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otView'
        },
        listLabels: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data() {
        return {
            options: [],
            check: '-1'
        }
    },
    created() {
    },
    mounted() {
        this.returnList()
    },
    methods: {
        // tagsBgColor(colorId) {
        //     let colors = [
        //         // "#808000",
        //         // "#f60",
        //         // "#5c6bc0",
        //         // "#090",
        //         // "#f00",
        //         // "#008cee",
        //         // "#800080"
        //         '#FF7165',
        //         '#5EA3F6',
        //         '#FFB735',
        //         '#37CBE3',
        //         '#8BD867',
        //         '#9B80F2',
        //         '#763626',
        //         '#FD8EC4',
        //         '#BFBF17',
        //         '#909399',
        //         '#D0021B'
        //     ]
        //     return colors[colorId - 1]
        // },
        submit() {
            let obj = {}
            if (this.check != -1) {
                obj[this.list.fieldName] = this.check
            }
            return obj
        },
        clear() {
            this.check = '-1'
        },
        changeItem(value) {
            let _this = this
            this.$emit('update:controlData', value)
            if (_this.check !== value) {
                _this.check = value
                let newValue = {
                    key: _this.list.fieldName,
                    value: value
                }
                _this.$emit('change', newValue)
            }
        },
        returnList() {
            let options = []
            options = this.listLabels
            options.unshift({
                labelId: '-1',
                labelName: '不限'
            })
            this.options = options
        }
    },
    computed: {
    },
    watch: {
        sysBoxValue: function (val, old) {
            this.returnList()
        }
    },
    components: {}
    // components: Object.assign({

    // }, Controls, GroupControls)
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.searchLabels{
    .rightBox{
        min-height: 36px;
        .label,.noLabel{
            display: inline-block;
            .width(65px);
            .height(26px);
            text-align: center;
            .line-height(26px);
            .border-radius(4px);
            .font-size(12px);
            .margin-bottom(17px);
            .margin-right(19px);
        }
        .label1{
            color: #606266;
            background: #EFEFEF;
        }
        .label2{
            color: white;
            background: #D0021B;
            border: 1px solid #D0021B;
        }
        .control{
            margin-bottom:0;
            line-height:33px;
            display: inline-block;
        }
    }
}

</style>
