
<template>
    <div v-show="screenSearch" class="screenSearch">
        <van-popup v-model="screenSearch" position="right" :overlay="true" class="screenSearchPop" get-container="body" style="height:100%;">
            <ContentItem @screenSearchClick="screenSearchClick" @getListData="getListData" :filterType="filterType"></ContentItem>
        </van-popup>
    </div>
</template>
<script>
import ContentItem from './ContentItem/index.vue'
export default {
    name: 'screenBox',
    data() {
        return {
            screenSearch: false
        }
    },
    props: {
        filterType: {
            type: [String, Number],
            default: ''
        }
    },
    created() {
    },
    mounted() {
    },
    watch: {
    },
    computed: {
    },
    methods: {
        searchScreenClick() {
            this.screenSearch = true
        },
        screenSearchClick(type) {
            this.screenSearch = type
        },
        getListData(obj) {
            this.$emit('getListData', obj)
            this.screenSearch = false
        }
    },
    components: {
        'ContentItem': ContentItem
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
