
<template>
    <div class="screenSearchPop">
        <div class="searchSetBox">
            <template v-if="filterType == 0">
                <div class="searchSet">
                    <div class="title">异常情况</div>
                    <searchLabelsV ref="searchLabelsV1" :options="anomalyList" @anomalyCheck="anomalyCheck" @clearFilfter="clearFilfter"></searchLabelsV>
                </div>
            </template>
            <template v-if="filterType == 1">
                <div class="searchSet">
                    <div class="title">超时时间</div>
                    <searchLabelsV ref="searchLabelsV1" :options="timeOutList" @anomalyCheck="anomalyCheck" @clearFilfter="clearFilfter"></searchLabelsV>
                </div>
            </template>
            <template>
                <div class="searchSet">
                    <div class="title">日期</div>
                    <searchLabelsV ref="searchLabelsV2" :options="dateInterval" @anomalyCheck="anomalyCheck"></searchLabelsV>
                </div>
            </template>
        </div>
        <div class="footer">
            <div class="leftBut" @click="clear()">
                重置
            </div>
            <div class="rightBut" @click="submit()">
                完成
            </div>
        </div>
    </div>
</template>
<script>
import searchLabelsV from '../Vue/searchLabelsV.vue'
import tag from '../Vue/tag.vue'
export default {
    name: 'ContentItem',
    props: {
        filterType: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            searchData: [],
            anomalyList: [
                {
                    itemName: '不限',
                    type: '',
                    dictItemCode: 1
                },
                {
                    itemName: '异常邮件',
                    type: 'abnormal',
                    dictItemCode: 2
                },
                {
                    itemName: '连接超时',
                    type: 'timeOutError',
                    dictItemCode: 3
                },
                {
                    itemName: '邮局踢出',
                    type: 'dropOutError',
                    dictItemCode: 4
                },
                {
                    itemName: '密码错误',
                    type: 'authError',
                    dictItemCode: 5
                }
            ],
            dateInterval: [
                {
                    itemName: '不限',
                    days: '365',
                    dictItemCode: 1
                },
                {
                    itemName: '今天',
                    days: 0,
                    dictItemCode: 2
                },
                {
                    itemName: '昨天',
                    days: 1,
                    dictItemCode: 3
                },
                {
                    itemName: '7天内',
                    days: 7,
                    dictItemCode: 4
                },
                {
                    itemName: '30天内',
                    days: 30,
                    dictItemCode: 5
                }
            ],
            timeOutList: [
                {
                    itemName: '不限',
                    time: '',
                    dictItemCode: 1
                },
                {
                    itemName: '10-20分钟',
                    time: '1',
                    dictItemCode: 2
                },
                {
                    itemName: '20-30分钟',
                    time: '2',
                    dictItemCode: 3
                },
                {
                    itemName: '30-60分钟',
                    time: '3',
                    dictItemCode: 4
                },
                {
                    itemName: '1-3小时',
                    time: '4',
                    dictItemCode: 5
                },
                {
                    itemName: '3小时以上',
                    time: '5',
                    dictItemCode: 6
                }
            ],
            filfterObj: {
                type: '',
                days: '365' // 默认不限为365
            }
        }
    },
    created() {
    },
    mounted() {
    },
    watch: {
    },
    computed: {
    },
    methods: {
        anomalyCheck(params) {
            if (params.hasOwnProperty('type')) {
                this.filfterObj.type = params.type
            } else if (params.hasOwnProperty('days')) {
                this.filfterObj.days = params.days
            } else if (params.hasOwnProperty('time')) {
                this.filfterObj.time = params.time
            }
        },
        clearFilfter() {
            this.filfterObj.type = ''
            this.filfterObj.days = '365'
        },
        clear() {
            this.$refs.searchLabelsV1.clear()
            this.$refs.searchLabelsV2.clear()
        },
        submit() {
            this.$emit('getListData', this.filfterObj)
        }
    },
    components: {
        'searchLabelsV': searchLabelsV,
        'tag': tag
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
