<template>
    <div class="DocumentSearch">
        <div class="searchBox van-hairline--bottom">
            <search-bar class="searchBar" v-model="keywords" placeholder="输入邮箱/客户名称查询" :showBtnNow="true" @keyup.enter.native="toSearch" @btnClick="toggleSearch"></search-bar>
            <i class="iconfont icon-funnel" style="font-size:17px" @click="openSearchScreen"></i>
        </div>
        <div class="resultBox ">
            <scroll v-if="!isLoading&&list.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <div v-show="!isLoading&&list.length>0" ref="wrapper" class="wrapper">
                    <ul class="docList">
                        <li v-for="(item,index) in  list" class="docItem" :class="{'van-hairline--bottom':index!=list.length}" :key="index">
                            <div class="itemTitle">
                                <div class="left blod">{{item.mailAccount}}</div>
                                <div class="right">{{getLocalTime(item.longSentDate/1000)}}</div>
                            </div>
                            <div class="itemTitle">
                                <div class="left">{{item.companyName?item.companyName:''}}</div>
                                <div class="right red">{{Math.ceil(item.delayTime/(1000*60))}}分钟</div>
                            </div>
                            <div class="itemTitle">
                                <div class="left">
                                    {{item.subject}}
                                </div>
                                <div class="right red">
                                    <span>{{Math.ceil(item.delayTime/(1024*8))}}Kb</span>
                                    <span>{{item.containAttachment == 1?'包含附件': '不包含附件'}}</span>
                                </div>
                            </div>
                            <div class="itemTitle" style="text-align: right;">
                                <van-button round size="small" plain @click="toLogUrl(item.logFile)" v-show="item.hasOwnProperty('logFile') && item.logFile != ''">查看日志</van-button>
                                <van-button round size="small" plain @click="toMonitordetail(item.cId,item.mailAccount,item.id)">邮件监视</van-button>
                                <van-button round size="small" plain @click="toTaskmonitor(item.cId,item.mailAccount)">任务监视</van-button>
                            </div>
                        </li>
                    </ul>
                </div>
            </scroll>
            <nodata v-if="!isLoading&&list.length<=0" title="暂无数据"></nodata>
            <loading v-if="isLoading" size="30px"></loading>
        </div>
        <search-screen ref="searchScreen" @getListData="getListData" :filterType="filterType"></search-screen>
    </div>
</template>

<script>
import SearchBar from '../SearchBar/index'
import SearchScreen from '../SearchScreen/index'
import BScroll from 'better-scroll'
import Scroll from '@/components/Scroll/index'
import mixin from '../mixin.js'
import titleMixin from '@/mixin/title'
export default {
    name: 'Search',
    mixins: [mixin, titleMixin],
    title: '查询',
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            scroll: null,
            keywords: '',
            isLoading: false,
            list: [],
            docType: 'ALL',
            page: {
                pageN: 0,
                pageSize: 10,
                total: 0
            },
            days: '',
            startY: 0,
            filterType: '1',
            showSearch: false,
            daysType: 0
        }
    },
    props: {
        active: {
            type: [String, Number],
            default: ''
        }
    },
    mounted() {
        this.setMenu()
        // this.getDelayListData()
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    methods: {
        toLogUrl(url) {
            window.open(url)
        },
        getLocalTime(nS) {
            var date = new Date(parseInt(nS) * 1000)
            var year = date.getFullYear()
            var mon = date.getMonth() + 1
            var day = date.getDate()
            var hours = date.getHours()
            var minu = date.getMinutes()
            return year + '/' + mon + '/' + day + ' ' + hours + ':' + minu
        },
        toTaskmonitor(cId, mail) {
            this.$router.push(
                {
                    path: '/mailmonitor/taskmonitor',
                    query: {
                        corpId: cId, mailAccount: mail
                    }
                }
            )
        },
        toMonitordetail(cId, mail, id) {
            this.$router.push(
                {
                    path: '/mailmonitor/monitordetail',
                    query: {
                        corpId: cId, mailAccount: mail, days: this.days, id: id
                    }
                }
            )
        },
        openSearchScreen() {
            this.$refs.searchScreen.searchScreenClick()
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        setScroll() {
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.wrapper, {
                        click: true,
                        probeType: 3
                    })
                } else {
                    this.scroll.refresh()
                }
            })
        },
        async toSearch() {
            await this.gettaskListData()
        },
        toggleSearch() {
            this.showSearch = !this.showSearch
            if (!this.showSearch && this.keywords) {
                this.keywords = ''
                this.gettaskListData()
            }
        },
        async gettaskListData(days, type) {
            this.daysType = type
            this.days = days
            this.page.pageN = 1
            this.isLoading = true
            this.list = await this.getData()
            this.isLoading = false
        },
        cancelSearch(value) {
            this.keywords = value
        },
        async getListData(obj) {
            this.daysType = obj.time
            this.days = obj.days
            this.page.pageN = 1
            this.isLoading = true
            this.list = await this.getData()
            this.isLoading = false
        },
        async getData() {
            let durationBegin = ''
            let durationEnd = ''
            if (this.daysType == 1) {
                durationBegin = 10
                durationEnd = 20
            } else if (this.daysType == 2) {
                durationBegin = 20
                durationEnd = 30
            } else if (this.daysType == 3) {
                durationBegin = 30
                durationEnd = 60
            } else if (this.daysType == 4) {
                durationBegin = 60
                durationEnd = 180
            } else if (this.daysType == 5) {
                durationBegin = 180
                durationEnd = 1000
            } else if (this.daysType == 0) {
                durationBegin = ''
                durationEnd = ''
            }
            console.log(this.days)
            let list = []
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_abnormalMessgaes
                let params = {
                    days: this.days,
                    // type: this.type,
                    durationEnd: durationEnd,
                    durationBegin: durationBegin,
                    pageN: this.page.pageN,
                    pageSize: this.page.pageSize
                }
                if (this.keywords) {
                    params.keywords = this.keywords
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    list = res.data.data.dataList
                    this.page.total = res.data.data.totalNum
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                // this.isLoading = false
                console.log(error)
            }
            return list
        },
        async onPullingDown() {
            this.page.pageN = 1
            this.list = await this.getData()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        async getDelayListData() {
            let routerParams = this.$route.query
            this.days = routerParams.days
            this.page.pageN = 1
            this.isLoading = true
            this.list = await this.getData()
            this.isLoading = false
        },
        async onPullingUp() {
            if (this.list.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.list.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getData()
                this.list = this.list.concat(list)
                this.$nextTick(() => {
                    if (this.list.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.path == '/document/search') {
                this.setMenu()
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'search-bar': SearchBar,
        'search-screen': SearchScreen,
        'scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
